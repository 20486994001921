import { Component } from 'solid-js';
import { useTheme } from '@repo/ui/ThemeProvider';

export const ThemeJSONPreview: Component = () => {
  const theme = useTheme();

  return (
    <div class="p-4 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
      <h3 class="text-xs font-semibold text-gray-600 dark:text-gray-300 mb-2">
        Current Settings
      </h3>
      <pre
        class="text-xs bg-gray-100 dark:bg-gray-900 
                  text-gray-800 dark:text-gray-200
                  p-2 rounded overflow-auto"
      >
        {JSON.stringify(theme.value(), null, 2)}
      </pre>
    </div>
  );
};

export default ThemeJSONPreview;
