import { Component, createEffect, onCleanup } from 'solid-js';
import { isServer } from 'solid-js/web';
import { useTheme } from '../providers/ThemeProvider';
import { DARK_SCHEME_QUERY, THEME_NAMESPACE, THEME_KEY } from '../theme-types';
import { string } from 'zod';
export interface ThemeDocumentSyncProps {
  namespace?: string;
  key?: string;
}

export const ThemeDocumentSync: Component<ThemeDocumentSyncProps> = (
  props = {},
) => {
  const { namespace = THEME_NAMESPACE, key = THEME_KEY } = props;
  const themeStore = useTheme();

  // System theme preference sync
  createEffect(() => {
    if (isServer) return;

    const mediaQuery = window.matchMedia(DARK_SCHEME_QUERY);
    const theme = themeStore.value();

    // Update theme based on system preference if mode is 'system'
    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      if (theme.mode === 'system') {
        document.documentElement.classList.toggle('dark', e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleSystemThemeChange);
    onCleanup(() =>
      mediaQuery.removeEventListener('change', handleSystemThemeChange),
    );

    // Initial system preference sync
    if (
      theme.mode === 'system' &&
      !localStorage.getItem(`${props.namespace}.${props.key}`)
    ) {
      themeStore.update({
        mode: mediaQuery.matches ? 'dark' : 'light',
      });
    }
  });

  // Font size sync
  createEffect(() => {
    if (!isServer) {
      const theme = themeStore.value();
      document.documentElement.style.fontSize = `${theme.fontSize}px`;
    }
  });

  return null;
};

// import { Component, createEffect } from 'solid-js';
// import { useThemeSync } from '../hooks/useThemeSync';

// /**
//  * ThemeSync component binds to system preferences and updates the theme features.
//  *
//  * @returns
//  *
//  * @example
//  * ```tsx
//  * import { Component } from 'solid-js';
//  * import { ThemeProvider } from '@repo/ui/ThemeProvider';
//  * import { ThemeDocumentSync } from '@repo/ui/ThemeDocumentSync';
//  *
//  * export const App: Component = () => {
//  *   return (
//  *     <ThemeProvider>
//  *       <ThemeDocumentSync />
//  *       <OtherComponents ... />
//  *     </ThemeProvider>
//  *   );
//  * };
//  * ```
//  */
// export const ThemeDocumentSync: Component = () => {
//   // This will automatically sync with system preferences
//   const themeStore = useThemeSync();

//   createEffect(() => {
//     // Apply theme to document
//     const theme = themeStore.value();
//     document.documentElement.classList.toggle('dark', theme.mode === 'dark');

//     // Apply font size
//     document.documentElement.style.fontSize = `${theme.fontSize}px`;
//   });

//   return null; // This is a utility component, no UI needed
// };
