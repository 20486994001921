import { Router } from '@solidjs/router';
import { FileRoutes } from '@solidjs/start/router';
import { Suspense } from 'solid-js';
import { ThemeProvider } from '@repo/ui/ThemeProvider';
import { ThemeDocumentSync } from '@repo/ui/ThemeDocumentSync';
import { UserPreferencesTab } from './features/preferences/UserPreferencesTab';
import { Header } from '~/components/Header/Header';
import './app.css';
import './tailwind.css';
import './assets/css/modal.css';
import './assets/css/style.css';
import './assets/css/twstyles.css';
import { IdentityProvider } from './features/auth/IdentityProvider';

export default function App() {
  return (
    // <IdentityProvider>
    <ThemeProvider>
      <ThemeDocumentSync />
      <UserPreferencesTab />
      <Header />
      <Router
        root={(props) => (
          <Suspense fallback="Loading...">{props.children}</Suspense>
        )}
      >
        <FileRoutes />
      </Router>
    </ThemeProvider>
    // </IdentityProvider>
  );
}
