import {
  createSignal,
  onMount,
  createEffect,
  Accessor,
  Setter,
} from 'solid-js';
import { isServer } from 'solid-js/web';
import {
  DevActionsState,
  HistoryEntry,
  MAX_HISTORY_ITEMS,
} from './DevActions.types';

export const defaultDevActionsState: DevActionsState = {
  isExpanded: false,
  showHistory: false,
  showShortcuts: true,
  showModals: true,
  showPreferences: false,
  actionHistory: [],
  actionProps: {},
};

const STORE_KEY = 'dev:state';

export class DevActionsStore {
  private static instance: DevActionsStore;

  #state: Accessor<DevActionsState>;
  #setState: Setter<DevActionsState>;

  private constructor() {
    const [state, setState] = createSignal<DevActionsState>(
      defaultDevActionsState,
    );
    this.#state = state;
    this.#setState = setState;
    const [isLoaded, setIsLoaded] = createSignal(false);
    // Load preferences from localStorage on client-side mount
    onMount(() => {
      if (isServer) return;
      try {
        const stored = localStorage.getItem(STORE_KEY);
        if (stored) {
          const parsedDevActionsState = JSON.parse(stored) as DevActionsState;
          setState(parsedDevActionsState);
        }
      } catch (error) {
        console.error(
          'Error loading dev actions state from localStorage:',
          error,
        );
      }
      setIsLoaded(true);
    });

    // Save to localStorage whenever data changes
    createEffect(() => {
      if (!isServer && isLoaded()) {
        try {
          localStorage.setItem(STORE_KEY, JSON.stringify(state()));
        } catch (error) {
          console.error('Error saving preferences to localStorage:', error);
        }
      }
    });
  }

  public static getInstance(): DevActionsStore {
    if (!DevActionsStore.instance) {
      DevActionsStore.instance = new DevActionsStore();
    }
    return DevActionsStore.instance;
  }

  private updateState(updates: Partial<DevActionsState>): void {
    const newState: DevActionsState = {
      ...this.#state(),
      ...updates,
    };

    this.#setState(newState);
  }

  getState(): Accessor<DevActionsState> {
    return this.#state;
  }

  resetState(): void {
    this.updateState(defaultDevActionsState);
  }

  toggleExpanded(): void {
    const currentState = this.#state();
    this.updateState({
      isExpanded: !currentState.isExpanded,
    });
  }

  toggleHistory(): void {
    const currentState = this.#state();
    this.updateState({
      showHistory: !currentState.showHistory,
    });
  }

  toggleShortcuts(): void {
    const currentState = this.#state();
    this.updateState({
      showShortcuts: !currentState.showShortcuts,
    });
  }

  togglePreferences(): void {
    const currentState = this.#state();
    this.updateState({
      showPreferences: !currentState.showPreferences,
    });
  }

  addHistoryEntry(entry: HistoryEntry): void {
    const currentState = this.#state();
    const newHistory = [entry, ...currentState.actionHistory].slice(
      0,
      MAX_HISTORY_ITEMS,
    );
    this.updateState({
      actionHistory: newHistory,
    });
  }

  updateProps(actionId: string, props: Record<string, any>): void {
    const currentState = this.#state();
    const newProps = {
      ...currentState.actionProps,
      [actionId]: props,
    };

    this.updateState({
      actionProps: newProps,
    });
  }

  clearHistory(): void {
    this.updateState({
      actionHistory: [],
    });
  }

  clearProps(): void {
    this.updateState({
      actionProps: {},
    });
  }
}
