import { Component } from 'solid-js';
import ThemeModeSelect from './ThemeModeSelect';
import ThemeJSONPreview from './ThemeJSONPreview';
import ThemeFontSlider from './ThemeFontSlider';

export const ThemeEditor: Component = () => {
  return (
    <div class="flex flex-col space-y-4">
      <div class="p-4 space-y-4">
        <div class="space-y-2">
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Theme Mode
          </label>
          <ThemeModeSelect />
        </div>

        <div class="space-y-2">
          <label class="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Font Size
          </label>
          <ThemeFontSlider />
        </div>
      </div>

      <ThemeJSONPreview />
    </div>
  );
};

export default ThemeEditor;
