import { Component } from 'solid-js';
import { useTheme } from '@repo/ui/ThemeProvider';

export const ThemeModeSelect: Component = () => {
  const theme = useTheme();

  const handleThemeModeChange = async (newMode: 'light' | 'dark') => {
    theme.update({ mode: newMode });
  };

  return (
    <select
      value={theme.value().mode}
      onChange={(e) =>
        handleThemeModeChange(e.currentTarget.value as 'light' | 'dark')
      }
      class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 
             rounded-md shadow-sm 
             bg-white dark:bg-gray-700 
             text-gray-900 dark:text-gray-100
             focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
    >
      <option value="light">Light Mode</option>
      <option value="dark">Dark Mode</option>
    </select>
  );
};

export default ThemeModeSelect;
