export const MenuIcon = () => (
  <svg
    class="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 16 16"
    data-icon="open"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M0 3.75A.75.75 0 0 1 .75 3h14.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 3.75M0 8a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 8m.75 3.5a.75.75 0 0 0 0 1.5h14.5a.75.75 0 0 0 0-1.5z"
      clip-rule="evenodd"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    class="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    data-icon="close"
  >
    <polygon points="97.83 7.83 92.17 2.17 50 44.34 7.83 2.17 2.17 7.83 44.34 50 2.17 92.17 7.83 97.83 50 55.66 92.17 97.83 97.83 92.17 55.66 50 97.83 7.83" />
  </svg>
);

export const UserIcon = () => (
  <svg
    class="w-full h-full"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9_154)">
      <path
        d="M13.5 2.25C16.6016 2.25 19.125 4.77338 19.125 7.87612C19.125 10.9789 16.6016 13.5011 13.5 13.5011C10.3984 13.5011 7.875 10.9766 7.875 7.87612C7.875 4.77562 10.3984 2.25 13.5 2.25ZM13.5 0C9.15075 0 5.625 3.52575 5.625 7.87612C5.625 12.2265 9.15075 15.7511 13.5 15.7511C17.8493 15.7511 21.375 12.2242 21.375 7.87612C21.375 3.528 17.8493 0 13.5 0ZM20.6651 15.0221C20.106 15.5824 19.476 16.0695 18.7999 16.4869C22.0309 18.5951 23.7251 22.2052 24.3934 24.7511H2.583C3.2265 22.1749 4.89825 18.5602 8.17762 16.4734C7.49925 16.0526 6.87038 15.561 6.31238 14.9974C1.52213 18.4534 0 24.5666 0 27H27C27 24.5903 25.38 18.4984 20.6651 15.0221Z"
        fill="#010105"
      />
    </g>
    <defs>
      <clipPath id="clip0_9_154">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CartIcon = () => (
  <svg
    class="w-full h-full"
    width="20"
    height="26"
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4444 6.5V4.33333C14.4444 1.94025 12.4544 0 10 0C7.54556 0 5.55556 1.94025 5.55556 4.33333V6.5H0V26H20V6.5H14.4444ZM6.66667 4.33333C6.66667 2.5415 8.16222 1.08333 10 1.08333C11.8378 1.08333 13.3333 2.5415 13.3333 4.33333V6.5H6.66667V4.33333ZM17.7778 23.8333H2.22222V8.66667H5.55556V10.2917C5.55556 10.5907 5.80444 10.8333 6.11111 10.8333C6.41778 10.8333 6.66667 10.5907 6.66667 10.2917V8.66667H13.3333V10.2917C13.3333 10.5907 13.5822 10.8333 13.8889 10.8333C14.1956 10.8333 14.4444 10.5907 14.4444 10.2917V8.66667H17.7778V23.8333Z"
      fill="currentColor"
    />
  </svg>
);
