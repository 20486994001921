import {
  createContext,
  useContext,
  ParentComponent,
  createEffect,
} from 'solid-js';
import { isServer } from 'solid-js/web';
import { StorageHandlers, StorageRef } from '@repo/shared';
import {
  ThemePreferences,
  defaultTheme,
  THEME_NAMESPACE,
  THEME_KEY,
  ThemeMode,
} from '../theme-types';

const ThemeContext = createContext<StorageHandlers<ThemePreferences>>();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

interface ThemeProviderProps {
  initialTheme?: Partial<ThemePreferences>;
}

export const ThemeProvider: ParentComponent<ThemeProviderProps> = (props) => {
  // Initialize storage with merged defaults and SSR initial values
  const initialTheme = {
    ...defaultTheme,
    ...props.initialTheme,
  };

  const storageRef = StorageRef.cookieStorage(
    THEME_NAMESPACE,
    THEME_KEY,
    initialTheme,
  );

  // Sync theme with HTML classes
  createEffect(() => {
    if (!isServer) {
      const theme = storageRef.handlers.value();
      const resolvedMode = resolveThemeMode(theme.mode);
      document.documentElement.classList.toggle(
        'dark',
        resolvedMode === 'dark',
      );
    }
  });

  return (
    <ThemeContext.Provider value={storageRef.handlers}>
      {props.children}
    </ThemeContext.Provider>
  );
};

// Helper to resolve the actual theme mode based on system preference
export function resolveThemeMode(mode: ThemeMode): 'light' | 'dark' {
  if (isServer) return 'light'; // Default for SSR
  if (mode === 'system') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  }
  return mode;
}

// import { createContext, useContext, ParentComponent } from 'solid-js';
// import { StorageHandlers, StorageRef } from '@repo/shared';

// // Define theme types
// export interface ThemePreferences {
//   mode: 'light' | 'dark';
//   fontSize: number;
// }

// export const defaultTheme: ThemePreferences = {
//   mode: 'light',
//   fontSize: 14,
// };

// const ThemeContext = createContext<StorageHandlers<ThemePreferences>>();

// // Storage configuration
// const THEME_NAMESPACE = 'app';
// const THEME_KEY = 'theme';

// export const useTheme = () => {
//   const context = useContext(ThemeContext);
//   if (!context) {
//     throw new Error('useTheme must be used within a ThemeProvider');
//   }
//   return context;
// };

// export const ThemeProvider: ParentComponent = (props) => {
//   const storageRef = StorageRef.cookieStorage(
//     THEME_NAMESPACE,
//     THEME_KEY,
//     defaultTheme,
//   );

//   return (
//     <ThemeContext.Provider value={storageRef.handlers}>
//       {props.children}
//     </ThemeContext.Provider>
//   );
// };
