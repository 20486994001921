import { JSX } from 'solid-js';

export const MAX_HISTORY_ITEMS = 50;

export const SHORTCUTS = {
  TOGGLE_PANEL: 'ctrl+shift+d',
  CREATE_MODAL: 'ctrl+shift+c',
  PREVIEW_MODAL: 'ctrl+shift+p',
  PURCHASE_MODAL: 'ctrl+shift+b',
} as const;

export type PropertyType = 'text' | 'number' | 'select' | 'boolean';

export interface HistoryEntry {
  timestamp: number;
  actionId: string;
  props: Record<string, any>;
}

export interface DevActionsState {
  isExpanded: boolean;
  showHistory: boolean;
  showShortcuts: boolean;
  showModals: boolean;
  showPreferences: boolean;
  actionHistory: HistoryEntry[];
  actionProps: Record<string, Record<string, any>>;
}

export interface PropertyDefinition {
  type: PropertyType;
  label: string;
  defaultValue: string | number | boolean;
  options?: string[]; // For select type
  description?: string;
  validation?: (value: any) => boolean;
}

export interface ActionProps {
  [key: string]: PropertyDefinition;
}

export interface Action {
  group: string;
  id: string;
  label: string;
  shortcut?: string;
  action: (props: Record<string, any>) => void;
  props: ActionProps;
  children?: JSX.Element;
}
