import {
  Component,
  createSignal,
  createEffect,
  onMount,
  onCleanup,
} from 'solid-js';
import { DevActionsStore } from '../dev/DevActions.store';
import { ThemeEditor } from '@repo/ui/ThemeEditor';
import { isServer } from 'solid-js/web';

export const UserPreferencesTab: Component = () => {
  const devStore = DevActionsStore.getInstance();
  const devState = devStore.getState();

  const [position, setPosition] = createSignal({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = createSignal(false);
  const [dragStart, setDragStart] = createSignal({ x: 0, y: 0 });

  // Keyboard shortcut handler
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'p') {
      e.preventDefault();
      devStore.togglePreferences();
    }
  };

  // Drag handlers
  const handleDragStart = (e: MouseEvent) => {
    if ((e.target as HTMLElement).closest('.drag-handle')) {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position().x,
        y: e.clientY - position().y,
      });
    }
  };

  const handleDragMove = (e: MouseEvent) => {
    if (isDragging()) {
      const newX = e.clientX - dragStart().x;
      const newY = e.clientY - dragStart().y;

      // Constrain to viewport bounds
      const panel = document.querySelector('.preferences-panel') as HTMLElement;
      if (panel) {
        const maxX = window.innerWidth - panel.offsetWidth;
        const maxY = window.innerHeight - panel.offsetHeight;

        setPosition({
          x: Math.max(0, Math.min(newX, maxX)),
          y: Math.max(0, Math.min(newY, maxY)),
        });
      }
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  onMount(() => {
    if (isServer) return;

    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('mousemove', handleDragMove);
    window.addEventListener('mouseup', handleDragEnd);
  });

  onCleanup(() => {
    if (isServer) return;

    window.removeEventListener('keydown', handleKeyPress);
    window.removeEventListener('mousemove', handleDragMove);
    window.removeEventListener('mouseup', handleDragEnd);
  });

  return (
    <div
      class={`
        fixed
        bottom-0
        transition-all duration-300 ease-in-out
        ${devState().showPreferences ? 'opacity-100 visible' : 'opacity-0 invisible'}
        z-50
      `}
      style={{
        transform: `translate(${position().x}px, ${position().y}px)`,
        transition: isDragging() ? 'none' : 'opacity 300ms ease-in-out',
      }}
    >
      {/* Preferences Panel */}
      <div
        class={`
          preferences-panel
          bg-white dark:bg-gray-800 
          border border-gray-200 dark:border-gray-700 
          shadow-lg w-96 overflow-hidden
          ${isDragging() ? 'cursor-grabbing select-none' : ''}
        `}
      >
        {/* Header */}
        <div
          class={`
            drag-handle
            flex items-center justify-between p-4 
            border-b border-gray-200 dark:border-gray-700 
            bg-gray-50 dark:bg-gray-900
            cursor-grab active:cursor-grabbing
          `}
          onMouseDown={handleDragStart}
        >
          <h2 class="text-sm font-semibold text-gray-700 dark:text-gray-200 select-none">
            User Preferences
          </h2>
          <button
            onClick={() => devStore.togglePreferences()}
            class="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300
                   transition-colors w-8 h-8 flex items-center justify-center
                   rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            <span class="sr-only">Close preferences</span>✕
          </button>
        </div>

        {/* Content */}
        <div class="h-[calc(100vh-116px)] overflow-y-auto">
          <div class="transition-all duration-300 ease-in-out">
            <ThemeEditor />
          </div>
        </div>

        {/* Footer */}
        <div class="p-4 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
          <p class="text-xs text-gray-500 dark:text-gray-400">
            Press{' '}
            <kbd class="px-1 py-0.5 bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded">
              Ctrl
            </kbd>{' '}
            +{' '}
            <kbd class="px-1 py-0.5 bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded">
              Shift
            </kbd>{' '}
            +{' '}
            <kbd class="px-1 py-0.5 bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded">
              P
            </kbd>{' '}
            to toggle panel
          </p>
        </div>
      </div>
    </div>
  );
};
