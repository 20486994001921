import { Component } from 'solid-js';
import { useTheme } from '@repo/ui/ThemeProvider';

export const ThemeFontSlider: Component = () => {
  const theme = useTheme();

  const handleFontSizeChange = (newSize: number) => {
    theme.update({ fontSize: newSize });
  };

  return (
    <div class="flex items-center space-x-2">
      <input
        type="range"
        min="12"
        max="24"
        step="1"
        value={theme.value().fontSize}
        onChange={(e) => handleFontSizeChange(Number(e.currentTarget.value))}
        class="flex-1 h-2 bg-gray-200 dark:bg-gray-700 
               rounded-lg appearance-none cursor-pointer 
               accent-blue-500"
      />
      <span class="text-sm text-gray-600 dark:text-gray-300 w-8 text-right">
        {theme.value().fontSize}px
      </span>
    </div>
  );
};

export default ThemeFontSlider;
