import { createSignal, createEffect, onMount, untrack } from 'solid-js';
import { isServer } from 'solid-js/web';
import type { Setter, Accessor } from 'solid-js';
import { Logger } from '@repo/logger';
import { CookieOptions, CookieStorage, HttpSSRContext } from './CookieStorage';
import { FakeStorage, SSRContext, StorageImpl, StorageHandlers } from './index';

/**
 *
 * @example
 *
 * // Client-side usage
 * const preferences = new StorageRef('app', 'preferences', defaultPrefs, {
 *   storage: 'cookie',
 *   cookieOptions: {
 *     maxAge: 86400,
 *     sameSite: 'Strict'
 *   }
 * });
 *
 * // Server-side usage (in your SSR handler)
 * export async function handleRequest(request: Request) {
 *   const response = new Response();
 *   const context = { request, response };
 *
 *   const serverStorage = createServerStorage('app', 'session', defaultSession, context, {
 *     httpOnly: true,
 *     secure: true
 *   });
 *
 *   // Use the storage...
 *   const { value, setValue } = serverStorage.handlers;
 *
 *   // Your SSR logic here...
 *
 *   return response;
 * }
 */
export class StorageRef<T, TSSRContext extends SSRContext = HttpSSRContext> {
  #namespace: string;
  #name: string;
  #defaultValue: T;
  #storeType: 'local' | 'session' | 'cookie' | 'fake';
  #store: StorageImpl;
  #value: Accessor<T>;
  #setValue: Setter<T>;
  #isInitialized: Accessor<boolean>;
  #handlers: StorageHandlers<T>;
  #logger: Logger;
  #fqn: string;
  #cookieOptions?: CookieOptions;

  constructor(
    namespace: string,
    name: string,
    defaultValue: T,
    options: {
      storage?: 'local' | 'session' | 'cookie';
      ssrContext?: TSSRContext;
      cookieOptions?: CookieOptions;
    } = {},
  ) {
    this.#namespace = namespace;
    this.#name = name;
    this.#defaultValue = defaultValue;
    this.#fqn = `${this.#namespace}.${this.#name}`;
    this.#cookieOptions = options.cookieOptions;

    if (isServer) {
      if (options.storage === 'cookie' && options.ssrContext) {
        this.#storeType = 'cookie';
        this.#store = new CookieStorage<TSSRContext>(options.ssrContext);
      } else {
        this.#storeType = 'fake';
        this.#store = new FakeStorage();
      }
    } else {
      switch (options.storage) {
        case 'session':
          this.#storeType = 'session';
          this.#store = sessionStorage;
          break;
        case 'cookie':
          this.#storeType = 'cookie';
          this.#store = new CookieStorage();
          break;
        default:
          this.#storeType = 'local';
          this.#store = localStorage;
      }
    }

    const { value, setValue, isInitialized } = this.#initSignals();
    this.#value = value;
    this.#setValue = setValue;
    this.#isInitialized = isInitialized;
    this.#handlers = this.#buildHandlers();

    this.#logger = new Logger(`StorageRef(${this.#storeType}|${this.#fqn})`);
    this.#logger.info('Initializing StorageRef', {
      namespace,
      name,
      defaultValue,
      storeType: this.#storeType,
      hasSSRContext: !!options.ssrContext,
    });
  }

  static cookieStorage<T>(
    namespace: string,
    name: string,
    defaultValue: T,
    options?: Partial<CookieOptions>,
  ): StorageRef<T> {
    return new StorageRef(namespace, name, defaultValue, {
      storage: 'cookie',
      cookieOptions: {
        secure: true,
        sameSite: 'Strict',
        ...options,
      },
    });
  }

  static localStorage<T>(
    namespace: string,
    name: string,
    defaultValue: T,
  ): StorageRef<T> {
    return new StorageRef(namespace, name, defaultValue, {
      storage: 'local',
    });
  }

  static sessionStorage<T>(
    namespace: string,
    name: string,
    defaultValue: T,
  ): StorageRef<T> {
    return new StorageRef(namespace, name, defaultValue, {
      storage: 'session',
    });
  }

  static createServerStorage<T, TSSRContext extends SSRContext>(
    namespace: string,
    name: string,
    defaultValue: T,
    context: TSSRContext,
    options?: CookieOptions,
  ): StorageRef<T, TSSRContext> {
    return new StorageRef(namespace, name, defaultValue, {
      storage: 'cookie',
      ssrContext: context,
      cookieOptions: options,
    });
  }

  get fqn(): string {
    return this.#fqn;
  }

  get storeType(): string {
    return this.#storeType;
  }

  get cookieOptions(): CookieOptions | undefined {
    return this.#cookieOptions;
  }

  get defaultValue(): T {
    return this.#defaultValue;
  }

  get handlers(): StorageHandlers<T> {
    return this.#handlers;
  }

  // #saveToStore(value: T): void {
  //   const valueStr = JSON.stringify(value);

  //   if (this.#storeType === 'cookie' && this.#store instanceof CookieStorage) {
  //     this.#store.setItemWithOptions(this.#fqn, valueStr, this.#cookieOptions);
  //   } else {
  //     this.#store.setItem(this.#fqn, valueStr);
  //   }
  // }

  #initSignals() {
    const [value, setValue] = createSignal<T>(this.#defaultValue);
    const [isInitialized, setInitialized] = createSignal(false);

    onMount(() => {
      if (isServer) {
        this.#logger.debug('Skipping storage initialization on server');
        return;
      }

      this.#logger.debug('Mounting StorageRef', {
        fqn: this.#fqn,
        initial: this.#defaultValue,
      });
      try {
        const storedValue = this.#store.getItem(this.#fqn);
        if (storedValue !== null) {
          this.#logger.debug('Loading stored value', {
            fqn: this.#fqn,
            value: storedValue,
          });
          const parsed = JSON.parse(storedValue);
          // Use untrack to prevent triggering the storage effect
          untrack(() => setValue(() => parsed));
          this.#logger.info('Successfully loaded stored value', {
            fqn: this.#fqn,
            valueType: typeof parsed,
            value: parsed,
          });
        } else {
          this.#logger.info('No stored value found, using default', {
            fqn: this.#fqn,
            defaultValue: this.#defaultValue,
          });
        }
      } catch (error) {
        this.#logger.error(`Failed to load ${this.#storeType} storage`, error, {
          fqn: this.#fqn,
        });
      } finally {
        setInitialized(true);
      }
    });

    // Only save to storage when the value actually changes after initialization
    createEffect(() => {
      if (isServer || !isInitialized()) {
        this.#logger.debug('Skipping storage effect (server or uninitialized)');
        return;
      }

      const currentValue = value();
      const currentValueStr = JSON.stringify(currentValue);
      const storedValue = this.#store.getItem(this.#fqn);

      // Only save if the value is different from what's stored
      if (storedValue !== currentValueStr) {
        try {
          this.#logger.debug('Saving value to storage', {
            fqn: this.#fqn,
            valueType: typeof currentValue,
            curr: currentValue,
            value: currentValueStr,
          });

          this.#store.setItem(this.#fqn, currentValueStr);
          this.#logger.info('Successfully saved to storage', {
            fqn: this.#fqn,
            byteSize: currentValueStr.length,
            value: currentValueStr,
          });
        } catch (error) {
          this.#logger.error(
            `Failed to save to ${this.#storeType} storage`,
            error,
            {
              fqn: this.#fqn,
            },
          );
        }
      }
    });

    return { value, setValue, isInitialized } as const;
  }

  #buildHandlers(): StorageHandlers<T> {
    return {
      value: this.#value,
      setValue: this.#setValue,
      isInitialized: this.#isInitialized,
      update: (updates: Partial<T>) => {
        if (!this.#isInitialized()) {
          const error = new Error(
            'Cannot update storage before initialization',
          );
          this.#logger.error('Update attempted before initialization', error);
          throw error;
        }

        this.#logger.debug('Updating storage value', {
          fqn: this.#fqn,
          updates,
        });

        this.#setValue((prev) => {
          const newValue = { ...prev, ...updates };
          this.#logger.info('Storage value updated', {
            fqn: this.#fqn,
            updatedFields: Object.keys(updates),
            prev,
            newValue,
          });
          return newValue;
        });
      },
      reset: () => {
        if (!this.#isInitialized()) {
          const error = new Error('Cannot reset storage before initialization');
          this.#logger.error('Reset attempted before initialization', error);
          throw error;
        }

        this.#logger.info('Resetting storage to default value', {
          fqn: this.#fqn,
          defaultValue: this.#defaultValue,
        });

        this.#setValue(() => this.#defaultValue);
      },
      clear: () => {
        if (!this.#isInitialized()) {
          const error = new Error('Cannot clear storage before initialization');
          this.#logger.error('Clear attempted before initialization', error);
          throw error;
        }

        this.#logger.warn('Clearing all storage', {
          storeType: this.#storeType,
        });

        this.#store.clear();
        this.#setValue(() => this.#defaultValue);

        this.#logger.info('Storage cleared and reset to default');
      },
    };
  }
}
