import { isServer } from 'solid-js/web';
import { Logger } from '@repo/logger';
import { StorageImpl } from './index.js';
import { SSRContext } from './index.js';

// Add new interfaces for cookie-specific functionality
export interface CookieOptions {
  path?: string;
  domain?: string;
  maxAge?: number;
  expires?: Date;
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
  httpOnly?: boolean;
}

export class HttpSSRContext implements SSRContext {
  #request: Request;
  #response: { headers: Headers };
  #cookies: Record<string, string> = {};

  constructor(request: Request, response: { headers: Headers }) {
    this.#request = request;
    this.#response = response;

    const cookieHeader = this.#request.headers.get('cookie');
    if (cookieHeader) {
      this.#cookies = parseCookies(cookieHeader);
    }
  }

  getCookie(name: string): string | null {
    return this.#cookies[name] || null;
  }

  setCookie(cookieStr: string): void {
    console.log('[HttpSSRContext] setCookie', cookieStr);
    this.#response.headers.append('Set-Cookie', cookieStr);
  }
}

export interface CookieStorageImpl extends StorageImpl {
  setItemWithOptions(
    name: string,
    value: string,
    options?: CookieOptions,
  ): void;
  getOptions(name: string): CookieOptions | null;
}

// Helper function to parse cookies
function parseCookies(cookieStr: string): Record<string, string> {
  return cookieStr.split(';').reduce(
    (acc, pair) => {
      const [key, value] = pair.trim().split('=');
      if (key && value) {
        acc[decodeURIComponent(key)] = decodeURIComponent(value);
      }
      return acc;
    },
    {} as Record<string, string>,
  );
}

export class CookieStorage<TSSRContext extends SSRContext = HttpSSRContext>
  implements CookieStorageImpl
{
  #logger: Logger;
  #ssrContext?: TSSRContext;
  #cookieCache: Map<string, { value: string; options: CookieOptions }>;

  constructor(ssrContext?: TSSRContext) {
    this.#logger = new Logger('CookieStorage');
    this.#ssrContext = ssrContext;
    this.#cookieCache = new Map();

    if (ssrContext) {
      this.#logger.info('Initialized in SSR mode');
    }
  }

  getItem(name: string): string | null {
    if (isServer) {
      if (!this.#ssrContext) {
        this.#logger.warn('No SSR context available for reading cookies');
        return null;
      }
      return this.#ssrContext.getCookie(name);

      // const cookieHeader = this.#ssrContext.request.headers.get('cookie');
      // if (!cookieHeader) return null;

      // const cookies = parseCookies(cookieHeader);
      // return cookies[name] || null;
    }

    // Client-side implementation
    const cached = this.#cookieCache.get(name);
    if (cached) return cached.value;

    const cookies = parseCookies(document.cookie);
    return cookies[name] || null;
  }

  setItem(name: string, value: string): void {
    this.setItemWithOptions(name, value);
  }

  setItemWithOptions(
    name: string,
    value: string,
    options: CookieOptions = {},
  ): void {
    const {
      path = '/',
      domain,
      maxAge,
      expires,
      secure = true,
      sameSite = 'Lax',
      httpOnly = false,
    } = options;

    let cookieStr = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    cookieStr += `; Path=${path}`;
    if (domain) cookieStr += `; Domain=${domain}`;
    if (maxAge) cookieStr += `; Max-Age=${maxAge}`;
    if (expires) cookieStr += `; Expires=${expires.toUTCString()}`;
    if (secure) cookieStr += '; Secure';
    if (sameSite) cookieStr += `; SameSite=${sameSite}`;
    if (httpOnly) cookieStr += '; HttpOnly';

    if (isServer) {
      if (!this.#ssrContext) {
        this.#logger.warn('No SSR context available for setting cookies');
        return;
      }

      this.#ssrContext.setCookie(cookieStr);
      // this.#ssrContext.response.headers.append('Set-Cookie', cookieStr);
      this.#logger.debug('Set cookie in SSR context', { name, options });
    } else {
      document.cookie = cookieStr;
      this.#cookieCache.set(name, { value, options });
      this.#logger.debug('Set cookie in browser', { name, options });
    }
  }

  getOptions(name: string): CookieOptions | null {
    const cached = this.#cookieCache.get(name);
    return cached ? cached.options : null;
  }

  clear(): void {
    if (isServer) {
      this.#logger.warn('Clear operation not supported in SSR context');
      return;
    }

    const cookies = parseCookies(document.cookie);
    for (const name of Object.keys(cookies)) {
      this.setItemWithOptions(name, '', {
        expires: new Date(0),
        path: '/',
      });
    }

    this.#cookieCache.clear();
    this.#logger.info('Cleared all cookies');
  }
}
